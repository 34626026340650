import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import {SidenavService} from '../../services/sidenav/sidenav.service';
import {Theme, ThemeService} from "../../services/theme.service";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public indeterminate = 'indeterminate';
  public progress: boolean;
  public themes: Theme[];
  public theme: string;
  private overlayContainer: OverlayContainer;

  constructor(private themeService: ThemeService, public authService: AuthService, public router: Router, private sidenav: SidenavService) { }

  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.progress = true;
        }

        if (event instanceof NavigationEnd) {
          this.progress = false;
        }
      });

    this.theme = this.themeService.getDefaultTheme().viewValue;
    this.themes = this.themeService.getThemes();
  }

  public toggleTheme(value: Theme) {
    this.theme = value.viewValue;
    this.themeService.overlayContainerTheming(value.value);
    this.themeService.setTheme(value.value);
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    })
  }
}
