import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {OverlayContainer} from "@angular/cdk/overlay";

import * as _ from 'lodash';

export interface Theme {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: "root"
})
export class ThemeService {

  constructor(private overlayContainer: OverlayContainer){

  }

  private _themes: Theme[] = [
    { value: 'jw-tangerine-light-theme', viewValue: 'Tangerine' },
    { value: 'jw-tangerine-dark-theme', viewValue: 'Dark Tangerine'},
    { value: 'jw-blue-light-theme', viewValue: 'Blue'},
    { value: 'jw-blue-dark-theme', viewValue: 'Dark Blue'},
  ];

  private _theme: Subject<Theme> = new Subject<Theme>();

  public getThemes() {
    return this._themes;
  }

  public getDefaultTheme(): Theme {
    return this._themes[0];
  }

  public getTheme(): Observable<Theme> {
    return this._theme;
  }

  public setTheme(selectedTheme: string) {
    const _theme = _.find(this._themes, { value : selectedTheme });
    this._theme.next(_theme);
  }

  public overlayContainerTheming(themeClassName: string) {
    const cl = this.overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
    }

    this.overlayContainer.getContainerElement().classList.add(themeClassName);
  }
}
