import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SidenavService} from "./services/sidenav/sidenav.service";
import {AuthService} from "./services/auth/auth.service";
import {Theme, ThemeService} from "./services/theme.service";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit{
  public theme: Theme;
  public themes: Theme[] = [];

  constructor(
    private _themeService: ThemeService,
    private _overlayContainer: OverlayContainer,
    public authService: AuthService,
    public sidenav: SidenavService
  ){
    this.theme = this._themeService.getDefaultTheme();
    this._themeService.overlayContainerTheming(this.theme.value);
  }

  ngOnInit(): void {
    this.themes = this._themeService.getThemes();
    this._themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });
  }

  ngAfterViewInit(): void {
    let appRoot = document.getElementsByTagName('app-root');
    let loader = document.getElementById('preloader');

    appRoot[0].setAttribute("style", "display: none");
    loader.style.display = "block";
    setTimeout(() => {
      loader.style.display = "none";
      appRoot[0].setAttribute("style", "display: block");
    }, 3000);
  }
}
