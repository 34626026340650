import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// import { Users } from '../models/users.model';
import { HttpErrorResponse } from '@angular/common/http';
// import {post} from 'selenium-webdriver/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = '/api/login/connect/';
  private logoutUrl = '/api/login/disconnect/';
  private sessionUrl = '/api/sessions/get/';
  public isLoggedIn = false;
  public redirectUrl: string;
  public user: any;

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  constructor( private http: HttpClient ) { }

  public login(login, password) {
    return this.http.post(this.loginUrl, {login: login, password: password}, httpOptions)
      .pipe(
        tap(session => {
          this.isLoggedIn = true;
          this.user = session;
        })
      );
  }

  public logout() {
    return this.http.get(this.logoutUrl)
      .pipe(
        tap(session => {
          this.isLoggedIn = false;
        })
      );
  }

  public session() {
    return this.http.get<boolean>(this.sessionUrl);
  }
}
