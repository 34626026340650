import { Component, OnInit } from '@angular/core';
import {SidenavService} from '../../services/sidenav/sidenav.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor(public sidenav: SidenavService) { }

  ngOnInit() {
  }

  close(){
    this.sidenav.close();
  }
}
