import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../../services/auth/auth.service";
import { Router } from "@angular/router";
import { merge } from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {SidenavService} from "../../../services/sidenav/sidenav.service";


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  public loginForm: FormGroup;
  public isNotValidFields = true;
  public result = {login: '', password: ''};

  constructor( private fb: FormBuilder, private sidenav: SidenavService, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      this.sidenav.openLogin();
    }, 3500);

    this.loginForm = this.fb.group({
      login: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });
  }

  public connectMe = function () {
    this.authService.login(this.loginForm.get('login').value, this.loginForm.get('password').value)
      .subscribe(login => {
        if(this.authService.isLoggedIn){
          this.router.navigate(['/']);
        }
      });
  }
}
