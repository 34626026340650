import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, CanLoad, UrlSegment, Route
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators';
import { AuthService } from './auth.service';
import {Observable} from "rxjs";
import {SidenavService} from "../sidenav/sidenav.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private sidenav: SidenavService, private router: Router, private http: HttpClient) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkLogin(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(location.pathname);
  }

  checkLogin(url: string){
   return (this.authService.isLoggedIn || this.authService.session()
      .pipe(

        tap(session => {
          if (session) {
            this.authService.user = session;
            // this.authService.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.authService.user.avatar + ')');
            this.authService.isLoggedIn = true;
            this.sidenav.closeLogin();
            this.router.navigate(['/' + url]);
            return true;
          } else {
            this.authService.logout().subscribe(() => {
              this.router.navigate(['/login']);
              return false;
            });
          }
        })
      ));
  }

}

